import React, { useState, useRef } from 'react';
import '../App.css';
import { useSpring, a } from 'react-spring';
import { useMeasure } from 'react-use';
import { ResizeObserver } from '@juggle/resize-observer';

export default function ServicesMobile() {
  let refScroll1 = useRef();
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });
  const [ref1, { height: height1 }] = useMeasure({ polyfill: ResizeObserver });
  const [ref2, { height: height2 }] = useMeasure({ polyfill: ResizeObserver });
  const [ref3, { height: height3 }] = useMeasure({ polyfill: ResizeObserver });
  const [ref4, { height: height4 }] = useMeasure({ polyfill: ResizeObserver });
  const [ref5, { height: height5 }] = useMeasure({ polyfill: ResizeObserver });
  const [ref6, { height: height6 }] = useMeasure({ polyfill: ResizeObserver });
  const [refr1, { height: heightr1 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [refr2, { height: heightr2 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [refr3, { height: heightr3 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [refr4, { height: heightr4 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [refr5, { height: heightr5 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [refr6, { height: heightr6 }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [clicked, setClicked] = useState(0);
  const dropDown = useSpring({
    opacity: clicked === 1 ? 1 : 0,
    height: clicked === 1 ? height + 16 : 0,
  });
  const dropDown1 = useSpring({
    opacity: clicked === 2 ? 1 : 0,
    height: clicked === 2 ? height1 + 16 : 0,
  });
  const dropDown2 = useSpring({
    opacity: clicked === 3 ? 1 : 0,
    height: clicked === 3 ? height2 + 16 : 0,
  });
  const dropDown3 = useSpring({
    opacity: clicked === 4 ? 1 : 0,
    height: clicked === 4 ? height3 + 16 : 0,
  });
  const dropDown4 = useSpring({
    opacity: clicked === 5 ? 1 : 0,
    height: clicked === 5 ? height4 + 16 : 0,
  });
  const dropDown5 = useSpring({
    opacity: clicked === 6 ? 1 : 0,
    height: clicked === 6 ? height5 + 16 : 0,
  });
  const dropDown6 = useSpring({
    opacity: clicked === 7 ? 1 : 0,
    height: clicked === 7 ? height6 + 16 : 0,
  });
  const dropDownr1 = useSpring({
    opacity: clicked === 21 ? 1 : 0,
    height: clicked === 21 ? heightr1 + 16 : 0,
  });
  const dropDownr2 = useSpring({
    opacity: clicked === 22 ? 1 : 0,
    height: clicked === 22 ? heightr2 + 16 : 0,
  });
  const dropDownr3 = useSpring({
    opacity: clicked === 23 ? 1 : 0,
    height: clicked === 23 ? heightr3 + 16 : 0,
  });
  const dropDownr4 = useSpring({
    opacity: clicked === 24 ? 1 : 0,
    height: clicked === 24 ? heightr4 + 16 : 0,
  });
  const dropDownr5 = useSpring({
    opacity: clicked === 25 ? 1 : 0,
    height: clicked === 25 ? heightr5 + 16 : 0,
  });
  const dropDownr6 = useSpring({
    opacity: clicked === 26 ? 1 : 0,
    height: clicked === 26 ? heightr6 + 16 : 0,
  });
  return (
    <div id="services" className="services-mobile" ref={refScroll1}>
      <div className="corporate-services">
        <h1 className="services-main-title">Corporate Services</h1>
        <div className="service-card-mobile">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 1 ? 0 : 1);
            }}
          >
            Civil investigations/litigation support
          </h3>
          <a.div className="service-description" style={dropDown}>
            <div ref={ref}>
              <p>
                Bruce currently works with a range of experienced lawyers
                assisting companies with civil claims either issued by them or
                who are defending claims made against them. He is able to
                identify lines of enquiry and quickly get to the crux of the
                issue, often saving cost. He is very aware of the rules of
                evidence and all information collected is assimilated and
                documented in a format suitable for court presentation.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 2 ? 0 : 2);
            }}
          >
            Workplace theft
          </h3>
          <a.div className="service-description" style={dropDown1}>
            <div ref={ref1}>
              <p>
                Theft in the workplace can be difficult to detect, as it
                typically starts small. If the dishonest employees discover that
                they can get away undetected with these small thefts, they are
                likely to expand their efforts. Your business may soon be losing
                large sums of money each year and the larger or more diverse
                your business interests and assets are, the easier it is for a
                savvy employee to hide their criminal activities. Even if the
                thefts are discovered, it is often very difficult for business
                owners and corporations to identify the guilty party or parties,
                especially if several employees are involved in the cover-up.
              </p>
              <p>
                Bruce and his team can investigate any suspicious financial
                activity or losses occurring within your company, in relation to
                both current and former staff, including:
              </p>
              <ul>
                <li>
                  Theft of company assets, for example the disappearance of
                  equipment, stock and other resources
                </li>
                <li>Embezzlement of company funds</li>
                <li>Misuse of employee allowances or office supply funds</li>
                <li>
                  False invoicing and inventory reports that hide theft and
                  fraudulent activities
                </li>
                <li>
                  Intellectual property theft, such as employees selling their
                  ideas to your competitors, or profiting from ideas or designs
                  generated while in your employ and on company time
                </li>
                <li>All other types of fraud</li>
                <p>
                  Whether you are unsure about the causes of missing resources
                  and money or are certain that workplace theft is occurring and
                  want to uncover the perpetrators, a private investigator can
                  help you nip workplace thieving in the bud and make sure all
                  your employees are working for the good of your company.
                </p>
              </ul>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 3 ? 0 : 3);
            }}
          >
            Surveillance
          </h3>
          <a.div className="service-description" style={dropDown2}>
            <div ref={ref2}>
              <p>
                Maybe a business associate might be trying to defraud you, an
                employee may be trying to mislead you, a claimant may not
                actually be as unwell as they state or perhaps someone you care
                about has started a habit that is going to be harmful to them.
              </p>
              <p>
                You need to be 100% certain before you take any action but what
                if you are certain yet unable to prove it?
              </p>
              <p>
                Bruce is able to conduct discreet surveillance that may answer
                these questions. He may also be able to put your mind at ease if
                nothing untoward is occurring.
              </p>
              <p>
                Surveillance is suitable in a number of circumstances including:
              </p>
              <ul>
                <li>Carry out surveillance on an employee</li>
                <li>Investigate a person’s background and lifestyle</li>
                <li>Locate a person’s place of work</li>
                <li>Find out a person’s home address</li>
                <li>Observation on a particular location</li>
                <li>Observe a working environment</li>
              </ul>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 4 ? 0 : 4);
            }}
          >
            Asset tracing
          </h3>
          <a.div className="service-description" style={dropDown3}>
            <div ref={ref3}>
              <p>
                Bruce has considerable experience in locating missing assets,
                particularly missing cars, boats and heavy machinery. His work
                is often in the insolvency field and leads to the recovery of
                funds to be dispersed by liquidators to creditors.
              </p>
              <p>
                He is available to close down premises, put appropriate security
                measures in place, complete stock and plant inventories and any
                other duties requested by liquidators.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 5 ? 0 : 5);
            }}
          >
            Background checks, Pre-employment Screening and CV verification
          </h3>
          <a.div className="service-description" style={dropDown4}>
            <div ref={ref4}>
              <p>
                In this competitive corporate landscape it is important to know
                that you can rely on the honesty and integrity of the people you
                are trusting with your company’s confidential information,
                assets and resources.
              </p>
              <p>
                Background information on any potential employee, business
                partner or corporation will enable you to make better informed
                and cost-effective hiring decisions, safeguarding your company
                against fraudulent candidates and further risks of corruption.
              </p>
              <p>Bruce offers a range of background checks, including:</p>
              <ul>
                <li>
                  <strong>Pre-employment checks</strong> – Bruce will
                  investigate the credentials, identity and integrity of any
                  person you plan to employ and make sure they are fully
                  verified. He will check references, educational and
                  professional qualifications and financial history, then
                  provide you with a detailed assessment of the candidate. He
                  will also make sure you know of their full employment history
                  so they cannot hide angry former bosses or a history of
                  workplace bullying or suspected workplace theft
                </li>
                <li>
                  <strong>Pre-purchase property investigation</strong> – in
                  which Bruce conducts surveillance at and around the property
                  site to investigate the neighbourhood and determine any
                  potential issues before purchase, such as anything that might
                  affect future resale value
                </li>
                <li>
                  <strong>Pre-tenancy checks</strong> – to make sure your
                  potential tenants have provided you with their full history,
                  and haven’t given you misleading information
                </li>
                <li>
                  <strong>Personal background checks</strong> – Bruce can gather
                  relevant personal information on any potential employee, to
                  answer important questions like, is this person reliable? Can
                  they be trusted with confidential information? Do they have
                  any conflicts of interest that could affect their work or
                  trustworthiness?
                </li>
                <li>
                  <strong>Corporation background checks</strong> – before
                  undertaking mergers and acquisitions or simply doing business
                  together, make sure that the corporations you plan to
                  affiliate your company with have good reputations and no
                  skeletons in their closets
                </li>
              </ul>
              <p>
                Above all, you do not want to put your company’s reputation and
                future at risk. Bruce can help you safeguard against
                untrustworthy individuals and dangerous business decisions. Call
                Bruce today and speak to a highly qualified private detective
                about how we can help you protect your business.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 6 ? 0 : 6);
            }}
          >
            School investigation response/security advice
          </h3>
          <a.div className="service-description" style={dropDown5}>
            <div ref={ref5}>
              <p>
                Board of Trustees are often confronted with issues around staff,
                pupils and families that need looking at by an experienced
                investigator. Bruce is often called upon to resolve issues
                confronting boards and can use his extensive life experience to
                come up with a solution that appeases all parties. If your
                school has such an issue, call Bruce and discuss the matter in a
                confidential manner without obligation.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 7 ? 0 : 7);
            }}
          >
            Document service (national and international)
          </h3>
          <a.div className="service-description" style={dropDown6}>
            <div ref={ref6}>
              <p>
                Process servers prepare affidavits and deliver a variety of
                legal documents which often require a particular person’s
                attendance in court. Bruce and his team travel to people’s
                homes, offices, and businesses to serve important court
                documents like divorce papers, subpoenas, statements of claim
                and other notices. They are ready to take on any challenge and
                work assiduously to serve documents on time while maintaining
                their professional integrity.
              </p>
              <p>
                We recognise that it’s incredibly challenging and time-consuming
                for individuals, businesses and government organisations to
                track down recipients and ensure they have received the legal
                documents that have been sent to them. Besides, we understand
                that many recipients try to evade process servers. We use the
                best surveillance techniques and private investigation
                strategies to trace even the most difficult subjects. With a
                broad range of investigation services, we can monitor the
                subject’s activities both online and in the real world and track
                them down quickly.
              </p>
            </div>
          </a.div>
        </div>
      </div>
      <div className="private-services">
        <h1 className="services-main-title">Private Services</h1>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 21 ? 0 : 21);
            }}
          >
            Criminal Investigations
          </h3>
          <a.div className="service-description" style={dropDownr1}>
            <div ref={refr1}>
              <p>
                Bruce currently works with a wide range of criminal
                practitioners around New Zealand. They use his experience to
                review Police cases, identify lines of enquiry, interview and
                brief potential defence witnesses and arrange for their
                attendance at court. His work is either funded privately or by
                Legal Services, a Government Agency that funds legal defence
                work. After reviewing legal aid cases, he prepares an estimate
                of costs to be forwarded by the acting lawyer to Legal Services
                for approval.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 22 ? 0 : 22);
            }}
          >
            Tracing lost persons
          </h3>
          <a.div className="service-description" style={dropDownr2}>
            <div ref={refr2}>
              <p>
                Knowing where to look for a missing person is very much like
                looking for a needle in a haystack. Perhaps, if you know the
                person well and know their habits and the places they tend to
                spend time in then you can narrow it down to a hay bale or two.
              </p>
              <p>
                However, if you are looking for a runaway teen, a long lost
                relative, a friend you lost contact with years ago, an old flame
                or your genetic parents, you don’t have a lot to go on.
              </p>
              <p>
                This is where professional help becomes essential. Bruce is an
                expert in locating missing people for all reasons.
              </p>
              <p>
                While the idea of looking for a long-lost family member is a
                nice one and we would happily do that full time, Bruce is also
                called upon to locate witnesses who are avoiding contact or
                individuals who owe you money and have gone to ground. It is
                likely that you do not know much about these people.
              </p>
              <p>
                Bruce has the networks and contacts, developed over many years
                of detective work, needed to find the people and facts that
                prefer to stay hidden.
              </p>
              <p>
                Our resources include personal, company and business networks,
                and we have the tracing expertise and equipment needed to
                effectively pursue all avenues of investigation.
              </p>
              <p>
                Bruce and his team can help find a missing person or business
                and provide you with the most up-to-date contact information and
                location details.
              </p>
              <p>Call and talk to Bruce about your needs.</p>
              <p>
                See link for more details about Bruce's dedicated tracing
                service.
              </p>
              <a
                href="https://mrtracer.co.nz"
                target="_blank"
                rel="noopener noreferrer"
                id="link-mrtracer"
              >
                mrtracer.co.nz
              </a>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 23 ? 0 : 23);
            }}
          >
            Divorce/matrimonial peace of mind investigations
          </h3>
          <a.div className="service-description" style={dropDownr3}>
            <div ref={refr3}>
              <p>
                It is often the ‘not knowing’ that is the worst aspect of
                cheating in a relationship.
              </p>
              <p>
                Bruce and his team can remove the doubts and either set your
                mind at ease or provide you with irrefutable evidence to
                confront the problem.
              </p>
              <p>
                Infidelity can happen in any relationship. Whether you suspect
                that you have a cheating boyfriend, cheating girlfriend, or
                cheating spouse, you want to know the truth, even if it’s bad!
                Your gut tells you it is definitely happening, an affair right
                under your nose, but you can’t get proof and you can’t confront
                your partner without it.
              </p>
              <p>
                Perhaps your friends are telling you that you are being too
                suspicious and your partner says you are paranoid and jealous.
                However, those are the kinds of lines a cheating wife or
                cheating husband would typically throw at you – making you feel
                guilty for not trusting them.
              </p>
              <p>
                So what can you do? If you get caught following them there will
                be hell to pay. Fishing for information can also easily get you
                in trouble. Their phone is suddenly locked and their sent emails
                erased. You are getting nowhere by asking pointed questions
                about where they have been when they get home late from work.
                You need evidence – proof that will enable you to make an
                informed decision about your future with this person. Most of
                all you need to find a way to end the emotional and
                psychological turmoil that a cheating spouse will put you
                through.
              </p>
              <p>
                Bruce can help. While it might seem like a good idea to do your
                own reconnaissance work, you may unknowingly end up committing
                illegal activities. Not to mention you run the risk of getting
                caught spying from the bushes and there is no way that scenario
                can turn out well.
              </p>
              <p>
                Bruce can investigate infidelity and affairs while remaining
                undetected, then provide you with the proof you need in a
                discreet and efficient manner. Because Bruce is licensed for
                detective work, he is legally able to investigate in ways you
                cannot.
              </p>
              <p>
                The confirmation that you have a cheating spouse is naturally
                very upsetting news. By using an investigator you can receive
                evidence of an affair in a safe and secure way and have the time
                to consider your reaction before confronting your cheating
                significant other. This will save you pain in the long run and
                also means that any action you take will be thought-out, rather
                than driven by emotion. If you want to get even rather than just
                getting mad, you will need all the information and evidence you
                can get to plan your next move. Contacting a lawyer with
                evidence provided by Bruce will mean you can get the upper hand
                in divorce or de facto claims.
              </p>
              <p>
                Another definite benefit of involving an investigator is that if
                your suspicions are not correct, and your partner is not having
                an affair, then it is best to find out from a professional
                detective, rather than finding out when you confront your
                partner – and get dumped for accusing them of infidelity.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 24 ? 0 : 24);
            }}
          >
            Drug , Alcohol or Gambling Addictions
          </h3>
          <a.div className="service-description" style={dropDownr4}>
            <div ref={refr4}>
              <p>
                Confronting a loved one, family member, colleague or business
                associate about their drug, gambling or drinking habit can be
                difficult to say the least. People get defensive about behaviour
                that they know is harmful to them and the more you try to speak
                to them about it, the more they’ll brush you off. You may even
                risk losing their friendship and driving them further into their
                addiction.
              </p>
              <p>
                Even though they may not want any help, you want to give it to
                them. You know that their behaviour is impacting their
                relationships, their work and their physical, mental and
                emotional wellbeing. If it happens to be a spouse, or partner,
                then you know first-hand how their addictive behaviour is
                affecting their life and yours and you have a right to want it
                to stop. However, it is difficult to know how to help someone
                struggling with a drug problem, gambling addiction or
                alcoholism.
              </p>
              <p>
                The first thing to do, before trying to tackle the problem, is
                to identify it. As someone who is close to them, you cannot
                confront them without evidence and you know they won’t tell you
                the truth about their problem.
              </p>
              <p>
                Consider hiring Bruce to investigate their addiction and to get
                all the information about their situation. Whether it’s your
                husband, wife, business partner or a family member, there are
                many questions to consider. Is it a single drug, or several? How
                much are they drinking and how has it affected their health? Is
                it all types of gambling, or is it one specifically? How much
                money have they lost or spent? Do they owe money to anyone
                because of their addiction? Who else are they putting at risk?
              </p>
              <p>
                Before you confront the person, you want answers to all these
                questions. Bruce is a highly qualified private detective who can
                undertake surveillance – with the utmost discretion – to get you
                the answers you need to plan your way forward. Whether you need
                to take action to protect yourself, or to find a way to convince
                them to get help, this information is vital.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 25 ? 0 : 25);
            }}
          >
            Family Court investigations
          </h3>
          <a.div className="service-description" style={dropDownr5}>
            <div ref={refr5}>
              <p>
                The break-up of a family is never easy. In some cases the
                process will happen naturally, amicably, and smoothly, with each
                party ending up with more or less the deal that they want.
                However, this is not always the case – for these times you may
                need Bruce’s private investigation services.
              </p>
              <p>
                Bruce and his team have a wide range of experience in handling
                these very sensitive matters with care, diligence, respect and
                discretion.
              </p>
              <h4>Your Questions?</h4>
              <ul>
                <li>
                  Do you feel that your ex-partner is not paying their full
                  share of child support?
                </li>
                <li>
                  Do you suspect that your ex-partner is neglecting your
                  children?
                </li>
                <li>
                  Perhaps you are concerned that there are unfinished matters on
                  which you need closure?
                </li>
              </ul>
              <p>
                If any of these situations apply to you, Bruce can provide you
                with the answers you need, with total discretion and
                confidentiality every time.
              </p>
            </div>
          </a.div>
        </div>
        <div className="service-card">
          <h3
            className="service-title"
            onClick={() => {
              setClicked(clicked === 26 ? 0 : 26);
            }}
          >
            Domestic child and teenager enquiries
          </h3>
          <a.div className="service-description" style={dropDownr6}>
            <div ref={refr6}>
              <p>
                We all dream of our teenagers being the kind of kids who stay at
                home to study and enjoy spending time with their parents. We
                were like that as teenagers, weren’t we?
              </p>
              <p>
                Sadly, we know the reality is that teenagers are the most likely
                to put themselves in dangerous situations and make bad
                decisions. Blame the hormones – they make teenage boys think
                they’re invincible and teenage girls think they’ll never get
                caught. What really worries us, the parents and guardians of
                teenagers, is the fact that they are oblivious to the many
                dangers surrounding them or simply believe that nothing bad will
                happen to <i>them</i>.
              </p>
              <p>
                While we can pray the worst won’t happen and that they’ll learn
                their lessons before any harm comes to them, we can also take
                steps to protect our teens. If you are concerned about any of
                your child’s behaviour or habits, or unsure what exactly they
                are getting up to, Bruce can investigate the matter further.
                Parents are often in the dark about what their teens are doing;
                sometimes it can be better than imagined and sometimes worse.
                But whatever it is, you want to know.
              </p>
              <p>
                Bruce investigates teenage criminal activity, drug use and
                possession, binge drinking, truancy, promiscuity, unusual
                internet habits, as well as any suspicious new friends and
                associates. His surveillance services include monitoring and
                observing your child’s activities and reporting back to you.
                This way we can provide you with detailed knowledge of your
                child’s life outside of home, without jeopardising their trust
                in you and without raising their suspicions that their parents
                are watching or concerned. Along with information, Bruce can
                give you peace of mind.
              </p>
              <p>
                Please call Bruce today to speak about your individual case and
                concerns.
              </p>
            </div>
          </a.div>
        </div>
      </div>
    </div>
  );
}
