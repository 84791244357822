import React from "react";
import "./App.css";
import Desktop from "./Components/Desktop";
import Mobile from "./Components/Mobile";
import { useMediaQuery } from "react-responsive";

function App() {
  const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 1224 });
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  return (
    <>
      {isDesktopOrLaptop && <Desktop />}
      {isTabletOrMobile && <Mobile />}
    </>
  );
}

export default App;
