import React from "react";


import "../App.css";

export default function About() {
  return (
    <div className="about-text">
      <h1 className="about-title">About</h1>
      <div className="about-description">
        <div>
          <p>
            Bruce Currie is a former senior Police Detective and now Licensed
            Private Investigator. He is licensed by the NZ Private Security
            Personnel Licensing Authority (Licence # 16-032957).
          </p>
          <p>
            He is one of New Zealand's most experienced investigators with over
            45 years experience providing a confidential service to both the
            business and private sectors.
          </p>
          <p>
            His services include criminal defence work, matrimonial (peace of
            mind), surveillance, fraud, staff theft, tracing individuals, Family
            Court matters, drugs, document service and general investigations.
          </p>
          <p>
            Bruce Currie can be contacted 24/7 by cell phone or email. Initial
            appraisals are free and his work covers all of New Zealand.
          </p>
          <p>
            He has associates nationwide and can also arrange for international
            enquiries to be completed.
          </p>
          <p>All enquiries are welcome.</p>
        </div>
      </div>
    </div>
  );
}
