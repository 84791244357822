import React from "react";
import Detective from "../Images/detective.jpg";

export default function HomeImage() {
  return (
    <div className="home-image-container">
      <img className="home-image" src={Detective} alt="detective"></img>
    </div>
  );
}
