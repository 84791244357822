import React from "react";

export default function Background() {
  return (
    <div className="background-container">
      <div className="background-text">
        <h1 className="background-title">Background</h1>
        <p>
          Bruce joined the New Zealand Police in 1975 and soon transferred to
          the Criminal Investigation Branch where he spent the most of the next
          25 years. At the time of his retirement in 1999, he had achieved the
          rank of Detective Senior Sergeant and was in charge of all serious
          crime investigations for the Waikato Rural Police District.
        </p>
        <p>
          During the course of his Police career, Bruce was involved in over 70
          successful homicide enquiries. These included the investigation into
          the death of British tourist Marjorie Hopegood in Hamilton and many
          other high profile cases.
        </p>
        <p>
          Bruce was also the Officer in Charge of the Police operation into the
          escape of 4 high profile prisoners from Pareremoremo Prison which was
          conducted in the Tairua area and gained both national and
          international media attention.
        </p>
        <p>
          During the 1990’s Bruce was the Officer in charge of the Waikato Drug
          squad, responsible for the cannabis recovery and covert drug
          operations run throughout the region.
        </p>
        <p>
          Since 2001, Bruce has operated his own private investigation practice
          servicing the Waikato, Bay of Plenty and Auckland regions.
        </p>
        <p>
          His Police background has been used by many legal practitioners to
          unravel cases against their clients, interview potential defence
          witnesses and brief them for court.
        </p>
        <p>
          In 2015 Bruce was the lead investigator for the Defence in the Cissy
          Chen murder trial in Auckland where her husband was found not guilty
          of her murder.
        </p>
        <p>
          Bruce is a highly experienced investigator who is able to provide
          sound advice at competitive rates.
        </p>
      </div>
    </div>
  );
}
